import TypeApiEnum from '@/helper/api/type-api.enum';
import InstanceApiInterface from '@/helper/api/instance-api.interface';

const config: InstanceApiInterface[] = [
    {
        id: TypeApiEnum.CATALOGUES,
        url: 'catalogues',
    },
    {
        id: TypeApiEnum.COMMANDE,
        url: 'commandes',
    },
    {
        id: TypeApiEnum.COMPTE_COMPTABLE,
        url: 'compte_comptables',
    },
    {
        id: TypeApiEnum.COMPTE_COMPTABLE_FICHE_CLIENT,
        url: 'comptes_comptables/fiche_client',
    },
    {
        id: TypeApiEnum.COMPTE_COMPTABLE_AUTOCOMPLETE,
        url: 'comptes_comptables/autocomplete',
    },
    {
        id: TypeApiEnum.ETABLISSEMENT,
        url: 'etablissements',
    },
    {
        id: TypeApiEnum.PANIER,
        url: 'paniers',
    },
    {
        id: TypeApiEnum.PIECE,
        url: 'pieces',
    },
    {
        id: TypeApiEnum.RETOUR,
        url: 'retours',
    },
    {
        id: TypeApiEnum.RETOUR_LISTE,
        url: 'liste_retours',
    },
    {
        id: TypeApiEnum.RETOUR_HISTORIQUE,
        url: 'historique_retours',
    },
    {
        id: TypeApiEnum.CONSIGNES_ORDONNEES,
        url: 'consignes_ordonnees',
    },
    {
        id: TypeApiEnum.MOTIF_RETOUR,
        url: 'motif_retours',
    },
    {
        id: TypeApiEnum.RETOUR_GARANTIE_PSA,
        url: 'retour_garantie_psas',
    },
    {
        id: TypeApiEnum.FACTURE,
        url: 'factures',
    },
    {
        id: TypeApiEnum.AVOIR,
        url: 'avoirs',
    },
    {
        id: TypeApiEnum.TYPE_COMMANDE,
        url: 'type_commandes',
    },
    {
        id: TypeApiEnum.MARQUE_VEHICULES,
        url: 'marque_vehicules',
    },
    {
        id: TypeApiEnum.MODELE_VEHICULES,
        url: 'modele_vehicules',
    },
    {
        id: TypeApiEnum.FORMULAIRE_REFERENCE_IDENTIFIANT,
        url: 'formulaire_reference_identifiant',
    },
    {
        id: TypeApiEnum.FORMULAIRE_IAM,
        url: 'formulaire_iam',
    },
    {
        id: TypeApiEnum.MEA_ACCUEIL,
        url: 'm_e_a_page_accueils',
    },
    {
        id: TypeApiEnum.UTILISATEUR_COURANT,
        url: 'utilisateurs/courant',
    },
    {
        id: TypeApiEnum.UTILISATEURS,
        url: 'utilisateurs',
    },
    {
        id: TypeApiEnum.OBJECTIFS_CC,
        url: 'objectifs_cc',
    },
    {
        id: TypeApiEnum.TELEVENTES,
        url: 'televentes',
    },
    {
        id: TypeApiEnum.PNEU_FORMULAIRE_RECHERCHE,
        url: 'pneus/formulaire-recherche',
    },
    {
        id: TypeApiEnum.PNEU_LISTE,
        url: 'pneus',
    },
    {
        id: TypeApiEnum.MESSAGE_ACCUEIL,
        url: 'message_accueils',
    },
    {
        id: TypeApiEnum.LIGNE_BON_LIVRAISON,
        url: 'ligne_bon_livraisons',
    },
    {
        id: TypeApiEnum.PLAQUE_PAR_DOMAINE,
        url: 'plaques/trouver_par_domaine',
    },
    {
        id: TypeApiEnum.SECTEURS,
        url: 'secteur_geographiques',
    },
    {
        id: TypeApiEnum.LIGNE_BON_LIVRAISONS_AUTOCOMPLETE_ADMIN,
        url: 'ligne_bon_livraisons/autocomplete_admin',
    },
    {
        id: TypeApiEnum.STATISTIQUES_AGENTS,
        url: 'statistiques_agents',
    },
];

export default config;
