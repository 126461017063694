export default [
    {
        path: '/televente',
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        meta: {},
        children: [
            {
                path: '',
                name: 'televente',
                component: () => import('@/domaine/televentes/creation-televente.vue'),
                meta: {
                    layout: 'template-page-defaut',
                },
            },
        ],
    },
];
