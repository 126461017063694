import SuiviCommandesItemOngletEnum from '@/domaine/suiviCommandes/suivi-commandes-item-onglet.enum';
import { useAuthStore } from '@/stores/auth.store';


export default [
    {
        path: '/suivi-commande',
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        beforeEnter: (to, from, next) => {
            if (useAuthStore().utilisateurActuel?.compteComptable == null) {
                next('/');
            } else {
                next();
            }
        },
        meta: {},
        children: [
            {
                path: '',
                name: 'suivi-commandes',
                redirect: {
                    name: SuiviCommandesItemOngletEnum.commandesEnCours,
                },
                meta: {
                    layout: 'template-page-defaut',
                },
                children: [
                    {
                        path: SuiviCommandesItemOngletEnum.commandesEnCours,
                        component: () => import('@/domaine/suiviCommandes/commandesEnCours/commandes-en-cours-onglet.vue'),
                        name: SuiviCommandesItemOngletEnum.commandesEnCours,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                    {
                        path: SuiviCommandesItemOngletEnum.livraisonDuJour,
                        component: () => import('@/domaine/suiviCommandes/livraisonDuJour/livraison-du-jour-onglet.vue'),
                        name: SuiviCommandesItemOngletEnum.livraisonDuJour,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                    {
                        path: SuiviCommandesItemOngletEnum.manquants,
                        component: () => import('@/domaine/suiviCommandes/manquants/manquants-onglet.vue'),
                        name: SuiviCommandesItemOngletEnum.manquants,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                    {
                        path: SuiviCommandesItemOngletEnum.devis,
                        component: () => import('@/domaine/suiviCommandes/devis/devis-onglet.vue'),
                        name: SuiviCommandesItemOngletEnum.devis,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                    {
                        path: SuiviCommandesItemOngletEnum.historique,
                        component: () => import('@/domaine/suiviCommandes/historique/historique-onglet.vue'),
                        name: SuiviCommandesItemOngletEnum.historique,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                ],
            },
        ],
    },
];
