import { http } from '@/services/axios/axios';
import { AuthPayloadResponseInterface } from '@/model/security/auth-payload-response.interface';
import { useAuthStore } from '@/stores/auth.store';
import { AxiosError } from 'axios';
import AuthUrlEnum from '@/enum/auth-urls';

interface GetTokenResponse {
    data: AuthPayloadResponseInterface;
}

interface ServiceApiResponse {
    hasError: boolean;
}

export interface UtilisateurLoginInterface {
    username: string;
    password: string;
    resterConnecter?: boolean | undefined;
}

export interface IndicateurLoginInterface {
    numTiers: string | undefined;
    password: string;
    plaque: string;
}

export interface UtilisateurCodeSecuriteInterface {
    email: string;
    code: string;
}

class AuthService {
    async connexion(utilisateur: UtilisateurLoginInterface): Promise<ServiceApiResponse> {
        try {
            const reponse: GetTokenResponse = await http.post<UtilisateurLoginInterface, GetTokenResponse>(AuthUrlEnum.connexion, utilisateur);

            if (reponse.data.token && reponse.data.refresh_token) {
                const authStore = useAuthStore();
                authStore.connexion(reponse.data);
                if (utilisateur.resterConnecter) {
                    authStore.setTokenCanExpire(false);
                }
            }
            return {
                hasError: false,
            };
            // @ts-ignore
        } catch (error: AxiosError) {
            return {
                hasError: true,
            };
        }
    }

    async connexionIndicateur(credentials: IndicateurLoginInterface): Promise<ServiceApiResponse> {
        try {
            const reponse = await http.post(AuthUrlEnum.connexionIndicateur, credentials);
            
            if (reponse.data.access_granted) {
                return {
                    hasError: false,
                    ...reponse.data
                };
            } else {
                return {
                    hasError: true,
                };
            }
            // @ts-ignore
        } catch (error: AxiosError) {
            return {
                hasError: true,
            };
        }
    }

    async demandeNouveauMotDePasse(email: string): Promise<ServiceApiResponse> {
        try {
            await http.post(AuthUrlEnum.demandeNouveauMdp, { email: email });
            return {
                hasError: false,
            };
            // @ts-ignore
        } catch (error: AxiosError) {
            return {
                hasError: true,
            };
        }
    }

    async verificationCodeMotDePasse(utilisateur: UtilisateurCodeSecuriteInterface): Promise<ServiceApiResponse> {
        try {
            await http.post(AuthUrlEnum.verificationCodeMdp, utilisateur);
            return {
                hasError: false,
            };
            // @ts-ignore
        } catch (error: AxiosError) {
            return {
                hasError: true,
            };
        }
    }
    async renouvellementNouveauMdp(utilisateur: UtilisateurLoginInterface): Promise<ServiceApiResponse> {
        try {
            await http.post(AuthUrlEnum.renouvellementMdp, utilisateur);
            return {
                hasError: false,
            };
            // @ts-ignore
        } catch (error: AxiosError) {
            return {
                hasError: true,
            };
        }
    }

    async deconnexion() {
        const authStore = useAuthStore();
        await http.post(AuthUrlEnum.deconnexion, { refresh_token: authStore.refreshToken });
        authStore.deconnexion();
        await this.router.push({ name: 'connexion' });
    }
}

export default AuthService;
