import { useAuthStore } from '@/stores/auth.store';

const Catalogues = () => import('./liste-catalogues.vue');

const section = 'catalogues';

export default [
    {
        path: '/' + section,
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        beforeEnter: (to, from, next) => {
            if (useAuthStore().utilisateurActuel?.compteComptable == null) {
                next('/');
            } else {
                next();
            }
        },
        meta: {},
        children: [
            {
                path: '',
                component: Catalogues,
                name: section,
                meta: {
                    layout: 'template-page-defaut',
                },
            },
        ],
    },
];
