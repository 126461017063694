enum AuthURLs {
    connexion = '/login_check',
    connexionIndicateur = '/indicateur/login',
    refreshToken = '/token/refresh',
    deconnexion = '/logout',
    demandeNouveauMdp = '/demande-changement-mdp',
    verificationCodeMdp = '/verification-code-mdp',
    renouvellementMdp = '/renouvellement-mdp',
}

export default AuthURLs;
