import RetoursItemOngletEnum from '@/domaine/retours/defaut/retours-item-onglet.enum';
import RetournerUnProduitEnum from '@/domaine/retours/defaut/retourner-un-produit/retourner-un-produit.enum';
import { useAuthStore } from '@/stores/auth.store';

export default [
    {
        path: '/suivi-commande',
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        beforeEnter: (to, from, next) => {
            if (useAuthStore().utilisateurActuel?.compteComptable == null) {
                next('/');
            } else {
                next();
            }
        },
        meta: {},
        children: [
            {
                path: '',
                name: 'retours',
                redirect: {
                    name: RetoursItemOngletEnum.demandesDeRetour,
                },
                children: [
                    {
                        path: RetoursItemOngletEnum.demandesDeRetour,
                        name: 'retours/demandes-de-retour',
                        redirect: {
                            name: RetoursItemOngletEnum.demandesDeRetour,
                        },
                        children: [
                            {
                                path: RetoursItemOngletEnum.demandesDeRetour,
                                component: () => import('@/domaine/retours/defaut/demandes-de-retour/listes-retours.vue'),
                                name: RetoursItemOngletEnum.demandesDeRetour,
                                meta: {
                                    layout: 'template-page-defaut',
                                },
                            },
                            {
                                path: RetournerUnProduitEnum.pieceDeRechange,
                                component: () => import('@/domaine/retours/defaut/retourner-un-produit/pages/piece-de-rechange/piece-de-rechange.vue'),
                                name: RetournerUnProduitEnum.pieceDeRechange,
                                meta: {
                                    layout: 'template-page-defaut',
                                },
                            },
                            {
                                path: RetournerUnProduitEnum.pieceDeRechangeFormulaire,
                                component: () =>
                                    import('@/domaine/retours/defaut/retourner-un-produit/pages/piece-de-rechange/piece-de-rechange-formulaire.vue'),
                                name: RetournerUnProduitEnum.pieceDeRechangeFormulaire,
                                meta: {
                                    layout: 'template-page-defaut',
                                },
                            },
                        ],
                    },
                    {
                        path: RetoursItemOngletEnum.consignes,
                        component: () => import('@/domaine/retours/defaut/liste-consignes/liste-consignes.vue'),
                        name: RetoursItemOngletEnum.consignes,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                    {
                        path: RetoursItemOngletEnum.garantiePsa,
                        name: 'retours/garanties-psa',
                        redirect: {
                            name: RetoursItemOngletEnum.garantiePsa,
                        },
                        children: [
                            {
                                path: RetoursItemOngletEnum.garantiePsa,
                                component: () => import('@/domaine/retours/defaut/garanties-psa/liste-garanties-psa.vue'),
                                name: RetoursItemOngletEnum.garantiePsa,
                                meta: {
                                    layout: 'template-page-defaut',
                                },
                            },
                            {
                                path: RetournerUnProduitEnum.garantiePSA,
                                component: () => import('@/domaine/retours/defaut/retourner-un-produit/pages/garantie-psa/retour-garantie-psa.vue'),
                                name: RetournerUnProduitEnum.garantiePSA,
                                meta: {
                                    layout: 'template-page-defaut',
                                },
                            },
                        ],
                    },
                    {
                        path: RetoursItemOngletEnum.demandesTraitees,
                        component: () => import('@/domaine/retours/defaut/demandes-traitees/demandes-traitees.vue'),
                        name: RetoursItemOngletEnum.demandesTraitees,
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                ],
            },
        ],
    },
];
