const LoginForm = () => import('../domaine/connexion/login/login-form.vue');
const MdpOubliePage = () => import('../domaine/connexion/mot-de-passe-oublie/mot-de-passe-oublie-page.vue');
const ConnexionPage = () => import('../domaine/connexion/connexion-template.vue');

export default [
    {
        path: '/login',
        component: ConnexionPage,
        name: 'login-page',
        children: [
            {
                path: '',
                component: LoginForm,
                name: 'login',
            },
        ],
    },
    {
        path: '/mot-de-passe-oublie',
        component: ConnexionPage,
        name: 'mot-de-passe-oublie-page',
        children: [
            {
                path: '',
                component: MdpOubliePage,
                name: 'mot-de-passe-oublie',
            },
        ],
    },
];
