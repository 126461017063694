<template>
    <notification
        classes-notification="border border-red-500"
        :suppression-automatique="suppressionAutomatique"
        @notification:fermer="emits('notification:fermer')"
    >
        <template #icone><XCircleIcon class="h-5 w-5 text-red-400" /></template>
        <template #titre>
            <slot name="titre"></slot>
        </template>
        <template #contenu>
            <slot name="contenu"></slot>
            <p v-if="!$slots.contenu">Veuillez réessayer ulterieurement ou bien si le problème persiste veuillez contactez le support.</p>
        </template>
        <template v-if="$slots.boutonPrincipalLibelle" #boutonPrincipalLibelle>
            <slot name="boutonPrincipalLibelle"></slot>
        </template>
        <template v-if="$slots.boutonSecondaireLibelle" #boutonSecondaireLibelle>
            <slot name="boutonSecondaireLibelle"></slot>
        </template>
    </notification>
</template>

<script setup lang="ts">
import Notification from '@/composants/generiques/notification/notification.vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';

const emits = defineEmits<{
    (e: 'notification:fermer'): void;
}>();

interface Props {
    suppressionAutomatique?: boolean;
}

withDefaults(defineProps<Props>(), {
    suppressionAutomatique: true,
});
</script>
