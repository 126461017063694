import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import AccueilRoutes from '@/domaine/accueil/routes';
import DesignSystemRoutes from '@/domaine/designsystem/routes';
import LoginRoutes from '@/router/connexion-routes';
import adminRoutes from '@/router/admin/admin-routes';
import defautRoutes from '@/router/defaut/defaut-routes';

const routes: RouteRecordRaw[] = [
    ...LoginRoutes,
    ...AccueilRoutes,
    ...DesignSystemRoutes,
    ...adminRoutes,
    ...defautRoutes,
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export default router;
