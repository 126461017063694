import { RouteRecordRaw } from 'vue-router';

const section = 'commandes';
const commandesAdminRoutes: RouteRecordRaw[] = [
    // {
    //     path: '/admin/' + section,
    //     meta: {},
    //     children: [
    //         {
    //             path: 'manquants',
    //             name: section + '-manquants',
    //             component: () => import('@/domaine/commandes/admin/manquants/liste-manquants.vue'),
    //         },
    //         {
    //             path: 'differes',
    //             name: section + '-differes',
    //             component: () => import('@/domaine/commandes/admin/differes/liste-differes.vue'),
    //         },
    //     ],
    // },
];

export default commandesAdminRoutes;
