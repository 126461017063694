import { RouteRecordRaw } from 'vue-router';
import professionEtablissementRoutes from '@/router/admin/routes/profession-etablissement-routes';
import secteurRoutes from '@/router/admin/routes/secteur-routes';
import transporteurRoutes from '@/router/admin/routes/transporteur-routes';
import RetoursAdminRoutes from '@/router/admin/routes/retours-admin-routes';
import CommandesAdminRoutes from '@/router/admin/routes/commandes-admin-routes';

const adminRoutes: RouteRecordRaw[] = [
    ...professionEtablissementRoutes,
    ...secteurRoutes,
    ...transporteurRoutes,
    ...RetoursAdminRoutes,
    ...CommandesAdminRoutes,
];

export default adminRoutes;
