import { Router } from 'vue-router';
import { useAuthStore } from '@/stores/auth.store';
import { http } from '@/services/axios/axios';
import AuthURLs from '@/enum/auth-urls';

export function initialiserNavigationGuards(router: Router) {
    const authStore = useAuthStore();
    router.beforeEach(async (to, from) => {
        if (authStore.isTokenExpired() && !authStore.tokenCanExpire) {
            const response = await http.post(AuthURLs.refreshToken, {
                refresh_token: authStore.refreshToken,
            });
            authStore.updateToken(response.data.token);
        }

        if (!authStore.estConnecte() && to.name !== 'login' && to.name !== 'mot-de-passe-oublie') {
            return { name: 'login' };
        } else if (authStore.estConnecte() && to.name === 'login') {
            return { name: 'accueil' };
        }
    });
}
