import { defineStore } from 'pinia';
import { ref } from 'vue';
import PanierLigne from '@/api/panier/interface/panier-ligne';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Panier from '@/api/panier/interface/panier';
import RessourceApi from '@/helper/api/ressource-api.class';
import TypeApiEnum from '@/helper/api/type-api.enum';

export const usePanierStore = defineStore(
    'panier',
    () => {
        const panier = ref<Panier>(null);
        const getPanierApi = async (): Promise<AxiosResponse<Panier, AxiosRequestConfig>> => {
            return await new RessourceApi(TypeApiEnum.PANIER).getItem('courant');
        };

        const getIdPanierLigne = (panierLigne: PanierLigne): string => {
            return panierLigne['@id'].split('/').pop() ?? null;
        };

        async function getPanier() {
            await getPanierApi().then(
                (response) => {
                    panier.value = response.data;
                },
                () => {
                    viderPanier();
                },
            );
        }

        function viderPanier(): void {
            panier.value = null;
        }

        async function modifierPanier(commandeUrgente: boolean, commentaire: string, repereClient: string) {
            const valeurs: object = {
                commandeUrgente: commandeUrgente,
                commentaire: commentaire,
                repereClient: repereClient,
            };

            await new RessourceApi(TypeApiEnum.PANIER).patch('courant', valeurs).then(() => getPanier());
        }

        async function validerPanier() {
            return await new RessourceApi(TypeApiEnum.PANIER).postAction('courant/valider').finally(() => getPanier());
        }

        async function ajoutLignePanier(reference: string, quantiteCommandee: number, referenceOrigine?: string | null): void {
            const panierLigne = {
                reference: reference,
                quantiteCommandee: quantiteCommandee | 0, // cast en entier
                referenceOrigine: null,
            };

            if (referenceOrigine !== undefined && referenceOrigine !== null && referenceOrigine !== reference) {
                panierLigne.referenceOrigine = referenceOrigine;
            }

            await new RessourceApi(TypeApiEnum.PANIER).post(panierLigne, 'courant/lignes').then(() => getPanier());
        }
        async function modifierLignePanier(panierLigne: PanierLigne, quantiteCommandee: number) {
            const id = getIdPanierLigne(panierLigne);
            if (id === null) {
                return null;
            }

            const valeurs: object = { quantiteCommandee: quantiteCommandee | 0 };

            await new RessourceApi(TypeApiEnum.PANIER).patch('courant/lignes/' + id, valeurs).then(() => {
                getPanier();
            });
        }
        async function supprimerLignePanier(panierLigne: PanierLigne) {
            const id = getIdPanierLigne(panierLigne);
            if (id === null) {
                return;
            }

            await new RessourceApi(TypeApiEnum.PANIER).delete('courant/lignes/' + id);
            await getPanier();
        }

        return {
            panier,
            getPanier,
            viderPanier,
            modifierPanier,
            ajoutLignePanier,
            modifierLignePanier,
            supprimerLignePanier,
            validerPanier,
        };
    },
    {
        persist: {
            enabled: true,
            strategies: [
                {
                    storage: localStorage,
                },
                {
                    key: 'parts_panier_storage',
                },
            ],
        },
    },
);
