<template>
    <button
        type="button"
        class="inline-flex items-center rounded-md font-medium leading-4 focus:outline-none"
        :class="[
            tailleBouton,
            {
                'cursor-pointer bg-primaire-500 text-white hover:bg-primaire-600': modele === 'primaire' && !desactive,
                'cursor-not-allowed bg-primaire-100 text-white': modele === 'primaire' && desactive,
                'cursor-pointer bg-secondaire-100 text-gray-700 hover:bg-secondaire-300': modele === 'secondaire' && !desactive,
                'cursor-not-allowed bg-gray-100 text-gray-300': modele === 'secondaire' && desactive,
                'cursor-pointer text-gray-500 hover:bg-gray-100 hover:text-gray-700': modele === 'tertiaire' && !desactive,
                'cursor-pointer text-red-700 hover:bg-red-100 hover:text-red-700': modele === 'erreur' && !desactive,
                'cursor-pointer bg-red-100 text-red-700': modele === 'erreur-bg' && !desactive,
                'cursor-not-allowed text-gray-200': modele === 'tertiaire' && desactive,

                'cursor-pointer text-success-dark hover:bg-success-light': modele === 'succes' && !desactive,
                'cursor-not-allowed text-success-dark opacity-30': modele === 'succes' && desactive,
                'cursor-pointer text-error-dark hover:bg-error-light': modele === 'erreur' && !desactive,
                'cursor-not-allowed text-error-dark opacity-30': modele === 'erreur' && desactive,
                'cursor-pointer text-brand-default hover:underline': modele === 'lien' && !desactive,
                'cursor-not-allowed text-brand-default opacity-30': modele === 'lien' && desactive,
                'opacity-30': modele === 'custom' && desactive,
            },
        ]"
        :disabled="desactive"
        :data-element="props.dataElement"
    >
        <slot></slot>
    </button>
</template>

<script setup lang="ts">
import { computed, withDefaults } from 'vue';

interface Props {
    taille?: 'petit' | 'moyen' | 'grand' | 'custom';
    desactive?: boolean;
    modele: string;
    dataElement?: string;
}

const props = withDefaults(defineProps<Props>(), {
    taille: 'grand',
    desactive: false,
    dataElement: undefined,
});

const tailleBouton = computed(() => {
    switch (props.taille) {
        case 'petit':
            return 'px-2.5 py-1.5 text-xs h-7';
        case 'moyen':
            return 'px-3 py-2 text-sm h-9';
        case 'grand':
            return 'px-4 py-2 text-base h-10';
        default:
            return '';
    }
});
</script>
