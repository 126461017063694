import { AxiosRequestConfig } from 'axios';

const AxiosBaseConfig: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/ld+json',
    },
};

const AxiosConfig: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/ld+json',
    },
};

const AxiosConfigPatch: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/merge-patch+json',
        Accept: 'application/ld+json',
    },
};

export { AxiosBaseConfig, AxiosConfig, AxiosConfigPatch };
