<script setup lang="ts">
import NotificationListe from '@/composants/generiques/notification/composants/notification-liste.vue';
import { useCookies } from 'vue3-cookies';
import { useFavicon } from '@vueuse/core';

const { cookies } = useCookies();
const codePlaque = cookies.get('code-plaque') ?? 'defaut';

setThemeClient(codePlaque);
setFaviconPath(codePlaque);

function setThemeClient(codePlaque: string) {
    import(`./shared/assets/theme-${codePlaque}/${codePlaque}.css`);
}

function setFaviconPath(codePlaque: string) {
    const favicon = useFavicon();
    favicon.value = `/favicon/${codePlaque}.ico`;
}
</script>

<template>
    <router-view />
    <notification-liste />
</template>
