import { RouteRecordRaw } from 'vue-router';

const section = 'secteur-geographique';
const secteurRoutes: RouteRecordRaw[] = [
    // {
    //     path: '/' + section,
    //     meta: {},
    //     children: [
    //         {
    //             path: '',
    //             name: section,
    //             component: () => import('@/domaine/secteurs/admin/liste-secteur.vue'),
    //         },
    //         {
    //             path: 'edition/:id?',
    //             name: section + '-edition',
    //             component: () => import('@/domaine/secteurs/admin/edit-form-secteur.vue'),
    //         },
    //     ],
    // },
];

export default secteurRoutes;
