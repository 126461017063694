import { useAuthStore } from '@/stores/auth.store';

const section = 'indicateurs';

export default [
    {
        path: '/' + section,
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        beforeEnter: (to, from, next) => {
            if (useAuthStore().utilisateurActuel?.compteComptable == null) {
                next('/');
            } else {
                next();
            }
        },
        meta: {},
        children: [
            {
                path: '',
                name: section,
                redirect: {
                    name: section + '.router',
                },
                meta: {
                    layout: 'template-page-defaut',
                },
                children: [
                    {
                        path: '',
                        name: section + '.router',
                        component: () => import('@/domaine/indicateurs/IndicateursRouter.vue'),
                    },
                    {
                        path: 'login',
                        name: section + '.login',
                        component: () => import('@/domaine/indicateurs/IndicateursForm.vue'),
                    },
                ],
            },
        ],
    },
];
