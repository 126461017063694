import CommandesItemOngletEnum from '@/domaine/commandes/defaut/commandes-item-onglet.enum';
import { useAuthStore } from '@/stores/auth.store';

const section = 'commandes';

export default [
    {
        path: '/' + section,
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        beforeEnter: (to, from, next) => {
            if (useAuthStore().utilisateurActuel?.compteComptable == null) {
                next('/');
            } else {
                next();
            }
        },
        meta: {},
        children: [
            {
                path: '',
                name: section,
                redirect: {
                    name: section + '.' + CommandesItemOngletEnum.pieceDeRechange,
                },
                children: [
                    {
                        path: CommandesItemOngletEnum.pieceDeRechange,
                        name: section + '.' + CommandesItemOngletEnum.pieceDeRechange,
                        component: () => import('@/domaine/commandes/defaut/pieceDeRechange/piece-de-rechange-onglet.vue'),
                    },
                    {
                        path: CommandesItemOngletEnum.gammeEquipementier,
                        name: section + '.' + CommandesItemOngletEnum.gammeEquipementier,
                        component: () => import('@/domaine/commandes/defaut/gammeEquipementier/gamme-equipementier-onglet.vue'),
                    },
                    {
                        path: CommandesItemOngletEnum.referenceAIdentifiant,
                        name: section + '.' + CommandesItemOngletEnum.referenceAIdentifiant,
                        component: () => import('@/domaine/commandes/defaut/referenceAIdentifiant/reference-a-identifiant-onglet.vue'),
                    },
                    {
                        path: CommandesItemOngletEnum.pneus,
                        name: section + '.' + CommandesItemOngletEnum.pneus,
                        component: () => import('@/domaine/commandes/defaut/pneus/pneus-onglet.vue'),
                    },
                ],
            },
        ],
    },
];
