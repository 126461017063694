import { http, httpPatch } from '@/services/axios/axios';
import AuthURLs from '@/enum/auth-urls';
import { useAuthStore } from '@/stores/auth.store';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import router from '@/router/routes';
import NotificationService from '@/services/notifications/notification.service';

const interceptor = () => {
    http.interceptors.request.use(gestionInterceptorRequete, gestionInterceptorErreurRequete);
    httpPatch.interceptors.request.use(gestionInterceptorRequete, gestionInterceptorErreurRequete);

    // On intercepte toutes les requêtes avant qu'elles partent
    function gestionInterceptorRequete(config: AxiosRequestConfig) {
        const authStore = useAuthStore();
        const token = authStore.bearerToken;

        // On n'envoie pas le token sur l'url de refresh car il est expiré à ce moment-là
        if (token && config.url !== AuthURLs.refreshToken && config.url !== AuthURLs.connexion) {
            if (!config.headers) {
                config.headers = {};
            }
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        // Ajout du paramètre _switch_user à l'url quand on est en train d'impersoner un utilisateur
        if (authStore.impersonationEnCours) {
            const url = new URL(`${config.baseURL}${config.url}`);
            const params = url.searchParams;

            params.set('_switch_user', authStore.emailUtilisateurImpersonne ?? '');

            url.search = params.toString();

            config.url = url.pathname.replace('/api', '') + url.search;
        }

        return config;
    }

    function gestionInterceptorErreurRequete(error: any) {
        return Promise.reject(error);
    }

    function gestionInterceptorSuccesReponse(responseAxios: AxiosResponse) {
        const headers = responseAxios.headers;

        return responseAxios;
    }

    async function deconnecterUtilisateur() {
        const authStore = useAuthStore();
        if (authStore.impersonationEnCours) {
            authStore.cancelImpersonation();
        }
        await authStore.deconnexion();
        await router.push({ name: 'login-page' });
    }

    http.interceptors.response.use(gestionInterceptorSuccesReponse, gestionInterceptorErreurReponse);
    httpPatch.interceptors.response.use(gestionInterceptorSuccesReponse, gestionInterceptorErreurReponse);
    async function gestionInterceptorErreurReponse(error: AxiosError) {
        const originalConfig = error.config;
        if ([400, 403, 500, 504].includes(error.response?.status)) {
            const titre = 'Une erreur est survenue.';
            let message = '';
            if (error.response.data['hydra:description']) {
                message = error.response.data['hydra:description'];
            }
            NotificationService.notifierErreur({ titre: titre, contenu: message });
            return Promise.reject(error);
        }

        if (originalConfig.url !== AuthURLs.connexion && error.response) {
            const authStore = useAuthStore();

            // TODO gérer proprement les tokens expirés && essayer de gérer avec un statut code 498 ?
            if (error.response.status === 401) {
                if (error.response.data.message === 'Expired JWT Token') {
                    if (!authStore.tokenCanExpire) {
                        try {
                            const rs = await http.post(AuthURLs.refreshToken, {
                                refresh_token: authStore.refreshToken,
                            });

                            authStore.updateToken(rs.data.token);
                            return http(originalConfig);
                        } catch (_error) {
                            // Si on arrive pas à rafraichir le token, on ne fait rien ici, on deconnectera l'utilisateur en fin de traitement
                        }
                    }
                }

                deconnecterUtilisateur();
            }
        }
        return Promise.reject(error);
    }
};

export default interceptor;
