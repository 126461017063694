<template>
    <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="afficher"
            :class="classesNotification"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-[9999]"
        >
            <div class="p-4">
                <div class="flex items-start">
                    <div v-if="$slots.icone" class="mt-0.5 flex-shrink-0">
                        <slot name="icone"></slot>
                    </div>
                    <div class="ml-3 w-0 flex-1">
                        <p class="text-sm font-semibold text-gray-900">
                            <slot name="titre">Titre notification</slot>
                        </p>
                        <p class="mt-1 whitespace-pre-wrap text-sm text-gray-500">
                            <slot name="contenu">Contenu de la notification</slot>
                        </p>
                        <div v-if="$slots.boutonPrincipalLibelle" class="mt-3 flex space-x-7">
                            <bouton-composant class="focus:outline-0 hover:bg-none" modele="primaire" @click="confirmerPrincipale">
                                <slot name="boutonPrincipalLibelle"></slot>
                            </bouton-composant>
                            <bouton-composant
                                v-if="$slots.boutonSecondaireLibelle"
                                class="focus:outline-0 hover:bg-none"
                                modele="tertiary"
                                @click="confirmerSecondaire"
                            >
                                <slot name="boutonSecondaireLibelle"></slot>
                            </bouton-composant>
                        </div>
                    </div>

                    <div class="ml-4 flex flex-shrink-0">
                        <button
                            type="button"
                            class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            @click="fermer"
                        >
                            <span class="sr-only">Close</span>
                            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import BoutonComposant from '@/composants/generiques/bouton/bouton-composant.vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const emits = defineEmits<{
    (e: 'notification:fermer'): void;
    (e: 'notification:action-principale'): void;
    (e: 'notification:action-secondaire'): void;
}>();

interface Props {
    classesNotification?: string;
    suppressionAutomatique?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    classesNotification: '',
    suppressionAutomatique: true,
});

const afficher = ref(true);

if (props.suppressionAutomatique) {
    setTimeout(() => {
        fermer();
    }, 4000);
}

function confirmerPrincipale() {
    emits('notification:action-principale');
    fermer();
}

function confirmerSecondaire() {
    emits('notification:action-secondaire');
    fermer();
}

function fermer() {
    afficher.value = false;
    emits('notification:fermer');
}
</script>
