const enum RetoursItemOngletEnum {

    retournerUnProduit = '/retours/retourner-un-produit',
    demandesDeRetour = '/retours/demandes-de-retour',
    demandesTraitees = '/retours/demandes-traitees',
    formulaireOuvert = '/retours/formulaire-ouvert',
    garantiePsa = '/retours/garanties-psa',
    consignes = '/retours/consignes',

    /*retournerUnProduit = 'retourner-un-produit',
    demandeDeRetour = 'demande-de-retour',
    historique = 'historique',
    formulaireOuvert = 'formulaire-ouvert',
    garantiePsa = 'garanties-psa',
    consignes = 'consignes',*/

}

export default RetoursItemOngletEnum;
