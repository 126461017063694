import { RouteRecordRaw } from 'vue-router';
import commandesRoutes from '@/router/defaut/routes/commandes-routes';
import SuiviCommandesRoutes from '@/domaine/suiviCommandes/routes';
import RetoursRoutes from '@/router/defaut/routes/retours-routes';
import DocumentsRoutes from '@/domaine/documents/routes';
import IndicateursRoutes from '@/router/defaut/routes/indicateurs-routes';
import CataloguesRoutes from '@/domaine/catalogues/routes';
import ImpersonationRoutes from '@/domaine/impersonation/impersonation-routes';
import TeleventeRoutes from '@/domaine/televentes/televente-routes';
import ClientsRoutes from '@/router/defaut/routes/fiches-client-routes';
import RetoursAdminRoutes from '@/router/admin/routes/retours-admin-routes';

const defautRoutes: RouteRecordRaw[] = [
    ...commandesRoutes,
    ...SuiviCommandesRoutes,
    ...RetoursRoutes,
    ...RetoursAdminRoutes,
    ...DocumentsRoutes,
    ...IndicateursRoutes,
    ...CataloguesRoutes,
    ...ImpersonationRoutes,
    ...TeleventeRoutes,
    ...ClientsRoutes,
];

export default defautRoutes;
