import DocumentsItemOngletEnum from '@/domaine/documents/documentsItemOnglet.enum';
import { useAuthStore } from '@/stores/auth.store';

const section = 'documents';

export default [
    {
        path: '/' + section,
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        beforeEnter: (to, from, next) => {
            if (useAuthStore().utilisateurActuel?.compteComptable == null) {
                next('/');
            } else {
                next();
            }
        },
        meta: {},
        children: [
            {
                path: '',
                name: section,
                redirect: {
                    name: section + '.' + DocumentsItemOngletEnum.factures,
                },
                meta: {
                    layout: 'template-page-defaut',
                },
                children: [
                    {
                        path: DocumentsItemOngletEnum.factures,
                        name: section + '.' + DocumentsItemOngletEnum.factures,
                        component: () => import('@/domaine/documents/factures/documentsOngletFactures.vue'),
                    },
                    {
                        path: DocumentsItemOngletEnum.avoirs,
                        name: section + '.' + DocumentsItemOngletEnum.avoirs,
                        component: () => import('@/domaine/documents/avoirs/documentsOngletAvoirs.vue'),
                    },
                    {
                        path: DocumentsItemOngletEnum.contrats,
                        name: section + '.' + DocumentsItemOngletEnum.contrats,
                        component: () => import('@/domaine/documents/contrats/documentsOngletContrats.vue'),
                    },
                ],
            },
        ],
    },
];
