const DesignSystem = () => import('./design-system.vue');

const section = 'designsystem';

export default [
    {
        path: '/designsystem',
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        meta: {},
        children: [
            {
                path: '',
                component: DesignSystem,
                name: 'designsystem',
                redirect: {
                    name: 'alertes',
                },
                meta: {
                    layout: 'template-page-defaut',
                },
                children: [
                    {
                        path: 'alertes',
                        name: 'alertes',
                        component: () => import('@/domaine/designsystem/componants/alertes-page.vue'),
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                    {
                        path: 'avatars',
                        name: 'avatars',
                        component: () => import('@/domaine/designsystem/componants/alertes-page.vue'),
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },

                    {
                        path: 'boutons',
                        name: 'boutons',
                        component: () => import('@/domaine/designsystem/componants/boutons-page.vue'),
                        meta: {
                            layout: 'template-page-defaut',
                        },
                    },
                ],
            },
        ],
    },
];
