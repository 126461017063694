import { mittEventEmitter } from '@/services/mitt/mitt.service';
import { NotificationInterface } from '@/composants/generiques/notification/interface/notification.interface';
import NotificationTypeEnum from '@/composants/generiques/notification/interface/notification-type.enum';

function notifierSucces(message: NotificationInterface): void {
    mittEventEmitter.emit(NotificationTypeEnum.NOTIFICATION_SUCCES, message);
}

function notifierErreur(message: NotificationInterface): void {
    mittEventEmitter.emit(NotificationTypeEnum.NOTIFICATION_ERREUR, message);
}

export default { notifierErreur, notifierSucces };
