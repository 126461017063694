import { defineStore } from 'pinia';
import { ref } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { AuthPayloadResponseInterface } from '@/model/security/auth-payload-response.interface';
import { UserInterface } from '@/model/security/user.interface';
import { RolesEnum } from '@/enum/roles';
import { http } from '@/services/axios/axios';
import { Utilisateur } from '@/domaine/impersonation/utilisateur.interface';
import ressourceApiClass from '@/helper/api/ressource-api.class';
import typeApiEnum from '@/helper/api/type-api.enum';
import { usePanierStore } from '@/stores/panier.store';
import router from '@/router/routes';
import { RouteLocationRaw } from 'vue-router';
import * as Sentry from '@sentry/vue';

export const useAuthStore = defineStore(
    'auth',
    () => {
        const bearerToken = ref<string | null>(null);
        const refreshToken = ref<string | null>(null);
        const emailUtilisateur = ref<string | null>(null);
        const rolesUtilisateur = ref<RolesEnum[]>([]);
        const tokenCanExpire = ref(true);

        const emailUtilisateurImpersonne = ref<string | null>(null);
        const rolesUtilisateurImpersonne = ref<RolesEnum[]>([]);
        const impersonationEnCours = ref<boolean>(false);
        const impersonationReferer = ref<RouteLocationRaw>('');
        const informationsUtilisateur = ref<Utilisateur | null>(null);
        const informationsUtilisateurImpersonne = ref<Utilisateur | null>(null);
        const utilisateurActuel = ref<Utilisateur | null>(null);

        function deconnexion(): void {
            http.post('/token/invalidate', {
                refresh_token: refreshToken.value,
            }).then(() => (refreshToken.value = null));
            bearerToken.value = null;
            refreshToken.value = null;
            emailUtilisateur.value = null;
            emailUtilisateurImpersonne.value = null;
            tokenCanExpire.value = true;
            rolesUtilisateur.value = [];
            rolesUtilisateurImpersonne.value = [];
            informationsUtilisateur.value = null;
            utilisateurActuel.value = null;
            Sentry.getCurrentScope().clear();
            Sentry.setUser(null);
        }

        function estConnecte(): boolean {
            return null !== bearerToken.value;
        }

        function connexion(payload: AuthPayloadResponseInterface): void {
            const user = jwtDecode<UserInterface>(payload.token);
            rolesUtilisateur.value = Object.values(user.roles);
            emailUtilisateur.value = user.username;
            bearerToken.value = payload.token;
            refreshToken.value = payload.refresh_token;
            chargerInformationsUtilisateur();
        }

        function impersonateUtilisateur(utilisateur: Utilisateur, routeSortieImpersonation: RouteLocationRaw = router.currentRoute.value) {
            chargerInformationsUtilisateurImpersonne(utilisateur);
            rolesUtilisateurImpersonne.value = Object.values(utilisateur.roles);
            emailUtilisateurImpersonne.value = utilisateur.email;
            impersonationEnCours.value = true;
            impersonationReferer.value = routeSortieImpersonation;
        }

        async function cancelImpersonation() {
            informationsUtilisateurImpersonne.value = null;
            utilisateurActuel.value = informationsUtilisateur.value;
            rolesUtilisateurImpersonne.value = [];
            emailUtilisateurImpersonne.value = null;
            impersonationEnCours.value = false;
            Sentry.getCurrentScope().clear();
            await router.push(impersonationReferer.value);
        }

        function updateToken(token: string): void {
            bearerToken.value = token;
        }

        function setTokenCanExpire(value: boolean) {
            tokenCanExpire.value = value;
        }

        function isTokenExpired() {
            return bearerToken.value && new Date().getTime() >= jwtDecode<UserInterface>(bearerToken.value).exp * 1000;
        }

        async function chargerInformationsUtilisateur() {
            await new ressourceApiClass(typeApiEnum.UTILISATEUR_COURANT).getCollection().then((response) => {
                const infoUtilisateur = response.data as Utilisateur;
                Object.assign(infoUtilisateur, {
                    numTiersComptable: response.data.compteComptable?.numTiersComptable,
                    adresse: response.data.etablissement?.adressePostalePrincipale.adresseTexte,
                    conseillerCommercial: response.data.etablissement?.secteurGeographique?.utilisateurs[0],
                    plaque: response.data.plaque,
                });
                informationsUtilisateur.value = infoUtilisateur;
                utilisateurActuel.value = infoUtilisateur;
                Sentry.setUser({
                    id: informationsUtilisateur.value.id,
                    username: informationsUtilisateur.value.email,
                    email: informationsUtilisateur.value.email,
                    roles: Object.values(informationsUtilisateur.value.roles),
                    name: informationsUtilisateur.value.prenom + ' ' + informationsUtilisateur.value.nom,
                    numTiersComptable: informationsUtilisateur.value.numTiersComptable,
                });
            });
        }

        async function checkUserRole(requiredRole: string): Promise<boolean> {
            try {
                const response = await http.get('/role_check', {
                    params: { role: requiredRole },
                });
                return response.data.is_granted;
            } catch (error) {
                console.error('Erreur lors de la vérification du rôle:', error);
                return false;
            }
        }

        async function chargerInformationsUtilisateurImpersonne(utilisateur: Utilisateur) {
            await new ressourceApiClass(typeApiEnum.UTILISATEURS).getItem(utilisateur.id).then((response) => {
                const infoUtilisateur = response.data as Utilisateur;
                Object.assign(infoUtilisateur, {
                    numTiersComptable: response.data.compteComptable?.numTiersComptable,
                    adresse: response.data.etablissement?.adressePostalePrincipale.adresseTexte,
                    conseillerCommercial: response.data.etablissement?.secteurGeographique?.utilisateurs[0],
                    plaque: response.data.plaque,
                });
                informationsUtilisateurImpersonne.value = infoUtilisateur;
                utilisateurActuel.value = infoUtilisateur;
                Sentry.setContext('impersonnation', {
                    id: informationsUtilisateurImpersonne.value.id,
                    username: informationsUtilisateurImpersonne.value.prenom + ' ' + informationsUtilisateurImpersonne.value.nom,
                    email: informationsUtilisateurImpersonne.value.email,
                    roles: informationsUtilisateurImpersonne.value.roles,
                    numTiersComptable: informationsUtilisateurImpersonne.value.numTiersComptable,
                });
            });
            const panierStore = usePanierStore();
            panierStore.getPanier();
        }

        return {
            bearerToken,
            refreshToken,
            rolesUtilisateur,
            emailUtilisateur,
            rolesUtilisateurImpersonne,
            emailUtilisateurImpersonne,
            impersonationEnCours,
            impersonationReferer,
            tokenCanExpire,
            informationsUtilisateur,
            informationsUtilisateurImpersonne,
            utilisateurActuel,
            connexion,
            impersonateUtilisateur,
            cancelImpersonation,
            deconnexion,
            updateToken,
            estConnecte,
            isTokenExpired,
            setTokenCanExpire,
            checkUserRole,
        };
    },
    {
        persist: {
            enabled: true,
            strategies: [
                {
                    storage: localStorage,
                },
                {
                    key: 'parts_auth_storage',
                },
            ],
        },
    },
);
