export default [
    {
        path: '/',
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        meta: {
            // libelleRoute: 'Accueil',
        },
        children: [
            {
                path: '',
                name: 'accueil',
                component: () => import('@/domaine/accueil/AccueilPage.vue'),
            },
        ],
    },
    // {
    //     path: '/',
    //     name: 'accueil',
    //     component: () => import('@/domaine/accueil/AccueilPage.vue'),
    //     meta: {
    //         layout: 'template-page-defaut',
    //     },
    // },
];
