<template>
    <notification
        classes-notification="border border-green-500"
        :suppression-automatique="suppressionAutomatique"
        @notification:fermer="emits('notification:fermer')"
    >
        <template #icone><CheckCircleIcon class="h-5 w-5 text-green-500" /></template>
        <template #titre>
            <slot name="titre"></slot>
        </template>
        <template #contenu>
            <slot name="contenu"></slot>
        </template>
        <template v-if="$slots.boutonPrincipalLibelle" #boutonPrincipalLibelle>
            <slot name="boutonPrincipalLibelle"></slot>
        </template>
        <template v-if="$slots.boutonSecondaireLibelle" #boutonSecondaireLibelle>
            <slot name="boutonSecondaireLibelle"></slot>
        </template>
    </notification>
</template>

<script setup lang="ts">
import Notification from '@/composants/generiques/notification/notification.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';

const emits = defineEmits<{
    (e: 'notification:fermer'): void;
}>();

interface Props {
    suppressionAutomatique?: boolean;
}

withDefaults(defineProps<Props>(), {
    suppressionAutomatique: true,
});
</script>
