import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { http } from '@/services/axios/axios';
import ListeInstanceApi from '@/helper/api/liste-instance-api';
import typeApiEnum from '@/helper/api/type-api.enum';

class RessourceApi {
    private instanceParametres: unknown;

    constructor(type: typeApiEnum) {
        this.chargerInstanceParametres(type);
    }

    public async getCollection(urlParametre?: string, urlSuffixe?: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any, AxiosRequestConfig>> {
        let url: string = this.getUrl();
        if (urlSuffixe !== undefined && urlSuffixe.length > 0) {
            url += urlSuffixe;
        }

        if (urlParametre !== undefined && urlParametre.length > 0) {
            url += '?' + urlParametre;
        }

        try {
            const response = await http.get(url, config);
            if (response.data === undefined) {
                console.error('data non disponible');
            }

            return response;
        } catch (erreur) {
            throw erreur;
        }
    }

    public async getItem(ressourceId: string, config?: AxiosRequestConfig): Promise<AxiosResponse<unknown, AxiosRequestConfig>> {
        const url: string = this.getUrl() + `/${ressourceId}`;

        try {
            const response = await http.get(url, config);
            if (response.data === undefined) {
                console.error('data non disponible');
            }

            return response;
        } catch (erreur) {
            throw erreur;
        }
    }

    public async postAction(ressourceId?: string): Promise<AxiosResponse<unknown, AxiosRequestConfig>> {
        let url: string = this.getUrl();
        if (ressourceId !== undefined) {
            url += `/${ressourceId}`;
        }

        try {
            return await http.post(url, {});
        } catch (erreur) {
            throw erreur;
        }
    }

    public async post(valeurs: object, ressourceId?: string): Promise<AxiosResponse<unknown, AxiosRequestConfig>> {
        let url: string = this.getUrl();
        if (ressourceId !== undefined) {
            url += `/${ressourceId}`;
        }

        try {
            return await http.post(url, valeurs);
        } catch (erreur) {
            throw erreur;
        }
    }

    public async patch(ressourceId: string, valeurs: object): Promise<AxiosResponse<unknown, AxiosRequestConfig>> {
        const url: string = this.getUrl() + `/${ressourceId}`;

        try {
            const response = await http.patch(url, valeurs, {
                headers: { 'Content-Type': 'application/merge-patch+json' },
            });
            if (response.data === undefined) {
                console.error('data non disponible');
            }

            return response;
        } catch (erreur) {
            throw erreur;
        }
    }

    public async delete(ressourceId: string): Promise<AxiosResponse<unknown, AxiosRequestConfig>> {
        const url: string = this.getUrl() + `/${ressourceId}`;
        try {
            return await http.delete(url);
        } catch (erreur) {
            throw erreur;
        }
    }

    private chargerInstanceParametres(type: typeApiEnum) {
        const listeInstanceApiPourunType = ListeInstanceApi.filter((instanceApi) => instanceApi.id === type);
        if (listeInstanceApiPourunType.length === 0) {
            throw new Error('Type[' + type + '] non configuré');
        }

        if (listeInstanceApiPourunType.length > 1) {
            throw new Error('Plusieurs type[' + type + '] identique dans la configuration');
        }

        this.instanceParametres = listeInstanceApiPourunType.shift();
    }

    private getUrl(): string {
        return this.instanceParametres.url;
    }
}

export default RessourceApi;
