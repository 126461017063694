import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import router from '@/router/routes';
import AxiosInterceptorService from '@/services/axios/axios-interceptor';
import { initialiserNavigationGuards } from '@/router/guards';
import AuthService from '@/services/security/auth.service';
import piniaPersist from 'pinia-plugin-persist';
import App from './App.vue';
import * as Sentry from '@sentry/vue';
import { createI18n } from 'vue-i18n';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();
const authService: AuthService = new AuthService();

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersist);

pinia.use(({ store }) => {
    store.router = markRaw(router);
});
app.use(pinia);

const codePlaque = cookies.get('code-plaque') ?? 'defaut';
import(`./locales/${codePlaque}/fr.json`).then((fr) => {
    const i18n = createI18n({
        locale: 'fr',
        fallbackLocale: 'fr',
        messages: { fr },
    });
    app.use(i18n);
});

app.provide('_authService', authService);

initialiserNavigationGuards(router);

AxiosInterceptorService();

Sentry.init({
    app,
    dsn: 'https://afd55bf27a9fe202c4e2674944bc2892@o124643.ingest.us.sentry.io/4507191331127296',
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        //Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    ignoreErrors: ['Request failed with status code 401'],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    //replaysSessionSampleRate: 0.1,
    //replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.mount('#app');
