<script setup lang="ts">
import { mittEventEmitter } from '@/services/mitt/mitt.service';
import { ref } from 'vue';
import { NotificationInterface } from '@/composants/generiques/notification/interface/notification.interface';
import NotificationTypeEnum from '@/composants/generiques/notification/interface/notification-type.enum';
import NotificationSuccess from '@/composants/generiques/notification/composants/notification-success.vue';
import NotificationErreur from '@/composants/generiques/notification/composants/notification-erreur.vue';

const listener = mittEventEmitter;

const notifications = ref<NotificationInterface[]>([]);

listener.on(NotificationTypeEnum.NOTIFICATION_SUCCES, (notification) => {
    (notification as NotificationInterface).type = NotificationTypeEnum.NOTIFICATION_SUCCES;
    afficherNotification(notification as NotificationInterface);
});
listener.on(NotificationTypeEnum.NOTIFICATION_ERREUR, (notification) => {
    (notification as NotificationInterface).type = NotificationTypeEnum.NOTIFICATION_ERREUR;
    afficherNotification(notification as NotificationInterface);
});

function afficherNotification(notification: NotificationInterface) {
    notifications.value.forEach((notif, index) => {
        if (notif.contenu === notification.contenu) {
            supprimerNotification(index);
        }
    });
    notifications.value.push(notification);
}
function supprimerNotification(index: number) {
    notifications.value.splice(index, 1);
}
</script>

<template>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 z-50 flex px-4 py-6 sm:p-6">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <template v-for="(notification, index) in notifications" :key="index">
                <notification-success
                    v-if="notification.type === NotificationTypeEnum.NOTIFICATION_SUCCES"
                    :suppression-automatique="notification.suppressionAutomatique"
                    @notification:fermer="supprimerNotification(index)"
                >
                    <template #titre>
                        {{ notification.titre }}
                    </template>
                    <template v-if="notification.contenu !== ''" #contenu>
                        {{ notification.contenu }}
                    </template>
                    <template v-if="notification.libelleBoutonPrincipal" #boutonPrincipalLibelle>
                        {{ notification.libelleBoutonPrincipal }}
                    </template>
                    <template v-if="notification.libelleBoutonSecondaire" #boutonSecondaireLibelle>
                        {{ notification.libelleBoutonSecondaire }}
                    </template>
                </notification-success>
                <notification-erreur
                    v-if="notification.type === NotificationTypeEnum.NOTIFICATION_ERREUR"
                    :suppression-automatique="notification.suppressionAutomatique"
                    @notification:fermer="supprimerNotification(index)"
                >
                    <template #titre>
                        {{ notification.titre }}
                    </template>
                    <template v-if="notification.contenu !== ''" #contenu>
                        {{ notification.contenu }}
                    </template>
                    <template v-if="notification.libelleBoutonPrincipal" #boutonPrincipalLibelle>
                        {{ notification.libelleBoutonPrincipal }}
                    </template>
                    <template v-if="notification.libelleBoutonSecondaire" #boutonSecondaireLibelle>
                        {{ notification.libelleBoutonSecondaire }}
                    </template>
                </notification-erreur>
            </template>
        </div>
    </div>
</template>
