//TODO Obsolète, a supprimer une fois le front des retours câlé
const enum RetournerUnProduitEnum {
    pieceDeRechange = '/retours/demandes-de-retour/retourner-un-produit/piece-de-rechange',
    pieceDeRechangeFormulaire = '/retours/demandes-de-retour/retourner-un-produit/piece-de-rechange-formulaire',
    garantiePSA = '/retours/retourner-un-produit/garantie-psa',
    garantieAutresConstructeurs = '/retours/retourner-un-produit/garantie-autres-constructeurs',
    pieceConsignee = '/retours/retourner-un-produit/piece-consignee',
    formulaireOuvert = '/retours/retourner-un-produit/formulaire-ouvert',
}

export default RetournerUnProduitEnum;
